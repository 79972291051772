.wrapper-banners {
	position: relative;
	width: 100%;
	height: 800px;
	overflow: hidden;
	@include respond-to('huge') {
		height: 700px;
	}
	@include respond-to('larger') {
		height: 600px;
	}
	@include respond-to('large') {
		height: 500px;
	}
	@include respond-to('medium') {
		height: 450px;
	}
	@include respond-to('small') {
		height: 400px;
	}
	@include respond-to('extra-small') {
		height: 350px;
	}
}

.wrap-banner-scroll {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include respond-to('medium') {
		position: static;
	}
}

.banner-item {
	width: 100%;
	min-height: 800px;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	@include respond-to('huge') {
		min-height: 700px;
	}
	@include respond-to('larger') {
		min-height: 600px;
	}
	@include respond-to('large') {
		min-height: 500px;
	}
	@include respond-to('medium') {
		min-height: 450px;
	}
	@include respond-to('small') {
		min-height: 400px;
	}
	@include respond-to('extra-small') {
		min-height: 350px;
	}
	// &::after {
	// 	content: '';
	// 	position: absolute;
	// 	#{$right}: 0;
	// 	top: 50%;
	// 	transform: translateY(-50%);
	// 	width: 1245px;
	// 	height: 100%;

	// 	@include respond-to('larger') {
	// 		background-image: none;
	// 		background-color: rgba($color: #000000, $alpha: 0.3);
	// 		width: 100%;
	// 		top: 0;
	// 		transform: none;
	// 	}
	// }
	&__text-wr {
		position: absolute;
		left: 0;
		right: 0;
		z-index: 1;
		max-width: 1230px;
		width: 100%;
		height: 100%;
		padding: 0 15px;
		margin: 0 auto;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url(../images/banner-shadow.png);
	}
	&__text {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		transform: translateY(-50%);
		max-width: 500px;
		width: 100%;
		margin: 0 auto;
		@include respond-to('large') {
			max-width: 400px;
		}
		@include respond-to('small') {
			max-width: 300px;
		}
	}
	&__title {
		font-size: 50px;
		font-weight: 700;
		color: #fff;
		line-height: 0.9;
		text-align: center;
		@include respond-to('large') {
			font-size: 40px;
		}
		@include respond-to('small') {
			font-size: 30px;
		}
	}
}