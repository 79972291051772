.hp-form {
	padding-top: 270px;
	padding-bottom: 110px;
	background-position: top center;
	background-size: cover;
	@include respond-to('1550') {
		padding-top: 200px;
	}
	@include respond-to('larger') {
		padding-bottom: 80px;
	}
	@include respond-to('medium') {
		padding-top: 130px;
		padding-bottom: 50px;
	}
	@include respond-to('small') {
		padding-top: 150px;
		padding-bottom: 30px;
	}
	&__title {
		font-size: 40px;
		font-weight: 700;
		color: #fff;
		text-align: center;
		line-height: 1;
		@include respond-to('extra-small') {
			font-size: 30px;
		}
	}
	&__subtitle {
		position: relative;
		margin-top: 10px;
		margin-bottom: 100px;
		font-size: 25px;
		font-weight: 400;
		color: $text;
		text-align: center;
		line-height: 1;
		@include respond-to('larger') {
			margin-bottom: 50px;
		}
		@include respond-to('extra-small') {
			margin-top: 10px;
			font-size: 22px;
		}
		& span {
			position: relative;
			padding-bottom: 40px;
			@include respond-to('larger') {
				padding-bottom: 25px;
			}
		}
	}
	&__decor {}
	.form-section {
		position: relative;
		&__form {
			max-width: 750px;
			width: 100%;
			margin: 0 auto;
			@include respond-to('medium') {
				width: 100%;
				float: none;
			}
			& .has-error .form-control {
				box-shadow: unset;
			}
			& .has-success .form-control {
				box-shadow: none;
			}
			& .form-group {
				margin-bottom: 0px;
				@include respond-to('small') {
					// margin-bottom: 10px;
					max-width: 350px;
					margin: 0 auto;
				}
			}
			& .help-block.with-errors {
				color: #000 !important;
			}
		}
	}

	.ftr-form {
		@include respond-to('small') {
			text-align: center;
			margin-bottom: 20px;
		}
		.has-feedback .form-control {
			padding-left: 12px;
			padding-right: 12px;
		}
	}

	.form-left {}

	.form-right {}

	.newslatter-input {
		max-width: 350px;
		width: 100%;
		height: 60px;
		border: none;
		background-color: transparent;
		position: relative;
		color: #fff;
		font-size: 25px;
		font-weight: 400;
		padding: 15px 0px !important;
		border-radius: 0;
		border-bottom: 1px solid $text;
		box-shadow: none;
		@include respond-to('medium') {
			font-size: 20px;
		}
		@include respond-to('small') {
			height: 45px;
			margin-left: auto;
			margin-right: auto;
			font-size: 18px;
		}
		&:focus {
			outline: none;
			&::placeholder {
				opacity: 0;
			}
		}
		&::placeholder {
			color: #fff;
			font-size: 25px;
			font-weight: 400;
			transition: opacity 0.4s ease;
			@include respond-to('medium') {
				font-size: 20px;
			}
			@include respond-to('medium') {
				font-size: 18px;
			}
		}
		&_area {
			height: 200px;
			resize: none;
			padding: 12px 0 !important;
		}
	}

	.newslatter-btn {
		display: block;
		margin: 50px auto 0;
		border: 0;
		@include respond-to('small') {
			margin-top: 20px;
		}
	}
}
