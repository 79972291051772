.wrap-content {
	padding: 70px 0 20px;
	@include respond-to('large') {
		padding: 50px 0 20px;
	}
	@include respond-to('medium') {
		padding: 40px 0 20px;
	}
	@include respond-to('small') {
		padding: 30px 0 0px;
	}
}

.wrap-all-content_not-banner {
	margin-top: 180px;
	@include respond-to('medium') {
		margin-top: 80px;
	}
}

.content-text {
	h2,
	h3 {
		font-size: 25px;
		font-weight: 700;
		line-height: 1.4;
		color: $text;
		margin: 20px 0 20px;
		@include respond-to('extra-small') {
			font-size: 20px;
		}
	}
	p {
		font-size: 20px;
		font-weight: 400;
		color: $text;
		line-height: 1.4;
		@include respond-to('medium') {
			font-size: 19px;
		}
		@include respond-to('small') {
			font-size: 18px;
		}
		@include respond-to('extra-small') {
			font-size: 17px;
			text-align: justify;
		}
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
}