.investors {
	text-align: center;
	&.sec-padd {
		padding-bottom: 30px;
		@include respond-to('extra-small') {
			padding-bottom: 10px;
		}
	}
	&__title {}
	&__slider {
		margin-top: 85px;
		@include respond-to('1100') {
			margin-top: 55px;
		}
		@include respond-to('small') {
			margin-top: 30px;
		}
	}
	&__slide-item-wrap {
		display: none;
		position: relative;
		height: 125px;
		line-height: 125px;
		padding: 0 5px;
		@include respond-to('small') {
			height: 105px;
			line-height: 105px;
		}
	}
	&__slide-item {
		display: inline-block;
		width: 100%;
		height: 100%;
		&:active,
		&:focus,
		&:hover {
			outline: none;
			& img {
				filter: grayscale(0%);
			}
		}
		&:first-child {
			display: block;
		}
		& img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			// display: inline-block;
			// margin: 0 auto;
			max-height: 98%;
			max-width: 98%;
			height: auto;
			// filter: grayscale(100%);
			// transition: filter 0.2s ease;
		}
	}
}

#clients-slide {
	& .slick-arrow {
		width: 12px;
		height: 46px;
	}
	& .slick-prev {
		#{$right}: -5px;
		#{$left}: auto;
		&::before {
			content: if-rtl('\f053', '\f054');
			font-family: "Font Awesome 5 Pro";
			color: $text;
			font-size: 25px;
			// font-weight: 400;
			// content: if-rtl(url('../images/arr-right.png'), url('../images/arr-left.png'));
		}
	}
	& .slick-next {
		#{$left}: -5px;
		#{$right}: auto;
		&::before {
			content: if-rtl('\f054', '\f053');
			font-family: "Font Awesome 5 Pro";
			color: $text;
			font-size: 25px;
			// font-weight: 400;
			// content: if-rtl(url('../images/arr-left.png'), url('../images/arr-right.png'));
		}
	}
}