.teams {
	&__item {
		border-bottom: 1px solid #d3d3d3;
		&:last-child {
			border-bottom: none;
			@include respond-to('small') {
				& .team-item {
					padding-bottom: 0;
				}
			}
		}
	}
}

.team-item {
	font-size: 0;
	padding: 50px 0;
	@include respond-to('medium') {
		padding: 30px 0;
	}
	&__photo {
		display: inline-block;
		vertical-align: top;
		font-size: initial;
		border-#{$right}: 3px solid $accent;
		padding-#{$right}: 10px;
		width: 23%;
		@include respond-to('1100') {
			width: 27%;
		}
		@include respond-to('medium') {
			width: 28%;
		}
		@include respond-to('small') {
			width: 100%;
			border-#{$right}: none;
			margin-bottom: 15px;
			padding-bottom: 15px;
			text-align: center;
		}
	}
	&__img {
		width: 222px;
		height: 222px;
		border-radius: 50%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		box-shadow: 0 0 15px 0 rgba($color: $text, $alpha: 0.2);
		@include respond-to('medium') {
			width: 180px;
			height: 180px;
		}
		@include respond-to('small') {
			margin: 0 auto;
		}
	}
	&__info {
		width: 77%;
		display: inline-block;
		vertical-align: top;
		padding-#{$left}: 55px;
		font-size: initial;
		@include respond-to('1100') {
			padding-#{$left}: 35px;
			width: 73%;
		}
		@include respond-to('medium') {
			width: 72%;
		}
		@include respond-to('small') {
			width: 100%;
			text-align: center;
			padding-#{$left}: 0;
		}
	}
	&__title {
		position: relative;
		font-size: 30px;
		font-weight: 700;
		color: $text;
		line-height: 1;
		@include respond-to('medium') {
			font-size: 25px;
		}
		@include respond-to('small') {
			font-size: 22px;
		}
	}
	&__subtitle {
		position: relative;
		margin-bottom: 20px;
		font-size: 25px;
		font-weight: 400;
		color: $text;
		@include respond-to('medium') {
			font-size: 22px;
		}
		@include respond-to('small') {
			font-size: 19px;
		}
	}
	&__text {
		& p {}
	}
}