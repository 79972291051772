footer {
	position: relative;
	width: 100%;
	padding: 100px 0 10px;
	z-index: 1;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	// box-shadow: 0 0 50px rgba(255, 255, 255, 0.25);
	@include respond-to('large') {
		padding: 20px 0 10px;
	}
}

.ftr-wrap {
	position: relative;
	z-index: 1;
	// & .section-limit {
	// 	max-width: 1730px;
	// }
	&__right {
		width: 68%;
		float: $right;
		padding-top: 110px;
		@include respond-to('1100') {
			float: none;
			width: 100%;
			padding-top: 10px;
			padding-bottom: 30px;
		}
	}
	&__left {
		width: 32%;
		float: $left;
		@include respond-to('1100') {
			float: none;
			width: 100%;
			max-width: 300px;
			margin: 0 auto 15px;
			text-align: center;
		}
		@include respond-to('medium') {
			margin-bottom: 0;
		}
	}
}

.link-col {
	float: $left;
	width: 33.33%;
	@include respond-to('small') {
		width: 50%;
	}
}

.hr-footer-bottom {
	border: 0;
	border-top: 1px solid $accent;
	margin-top: 40px;
	margin-bottom: 10px;
	@include respond-to('large') {
		margin-top: 20px;
		margin-bottom: 10px;
	}
	@include respond-to('extra-small') {
		margin-top: 10px;
	}
}

.ftr-logo {
	@include respond-to('medium') {
		text-align: center;
	}
	& img {
		max-width: 230px;
		@include respond-to('medium') {
			// max-width: 150px;
		}
	}
}

.ftr-contact {
	padding-top: 25px;
	max-width: 300px;
	@include respond-to('1100') {
		margin: 10px auto 10px;
		padding-top: 15px;
	}
	@include respond-to('medium') {
		padding-top: 0;
	}
	&__item {
		display: block;
		margin-bottom: 10px;
		// text-align: $right;
		@include link_no-hover($text);
		@include respond-to('medium') {
			margin-bottom: 5px;
		}
		& i {
			display: inline-block;
			vertical-align: middle;
			width: 15%;
			margin-#{$right}: -4px;
			color: $accent;
			font-size: 22px;
		}
		& span {
			display: inline-block;
			vertical-align: middle;
			padding-#{$left}: 4px;
			width: 84%;
			font-size: 18px;
			font-weight: 400;
			@include respond-to('medium') {
				// font-size: 15px;
				// font-weight: 300;
			}
		}
	}
}

.ftr-soc {
	margin-top: 20px;
	&__item {
		display: inline-block;
		margin-#{$right}: 10px;
		@include link_no-hover($accent);
		font-size: 27px;
	}
}

.site-footer__credit-holder {
	padding: 0px 0 10px;
	@include respond-to('medium') {
		padding: 0px 0 10px;
	}
}