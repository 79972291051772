.main-header {
	width: 100%;
	z-index: 99;
	position: fixed;
	top: 0;
	// background: #fff;

	transition: all 0.4s ease;
}


.header-desktop {
	height: 260px;
	background-image: url(../images/header-bg.png);
	background-position: center bottom;
	background-size: cover;
	background-repeat: repeat-x;
	pointer-events: none;
	transition: height 0.2s ease;
	@include respond-to('huge') {
		height: 200px;
		// background-image: none;
		// background-color: #000;
		// box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
	}
	@include respond-to('medium') {
		display: none;
	}
	& .section-limit {
		position: relative;
		pointer-events: all;
		max-width: 1830px;
	}
}

.logo {
	display: inline-block;
	padding-top: 15px;
	// position: absolute;
	// top: 20px;
	// #{$left}: 20px;
	transition: all 0.2s ease;
	@include respond-to('1100') {
		padding-top: 5px;
	}
	& img {
		max-width: 289px;
		transition: all 0.2s ease;
		@include respond-to('huge') {
			max-width: 220px;
		}
		@include respond-to('large') {
			max-width: 190px;
		}
		@include respond-to('1100') {
			max-width: 170px;
		}
	}
}

.header-search {
	float: $right;
	margin-top: 53px;
	transition: margin 0.2s ease;
	@include respond-to('huge') {
		margin-top: 40px;
	}
	@include respond-to('1100') {
		margin-top: 25px;
	}
}

.find-last {
	display: inline-block;
	// float: $left;
	height: 100%;
	vertical-align: top;
	@include respond-to('large') {
		// margin-#{$left}: 250px;
	}
	@include respond-to('1100') {
		// margin-#{$left}: 220px;
	}
	&_mobile {
		margin-#{$left}: 0;
		float: none;
		width: 100%;
		// border-#{$left}: 1px solid $accent;
		border-bottom: 1px solid $accent;
		& .search {
			width: 100%;
			& input {
				color: #949494;
				background-color: $text;
				&::placeholder {
					color: #949494;
				}
			}
			& i {
				font-size: 20px;
			}
		}
	}
}

.search {
	position: relative;
	width: 240px;
	@include respond-to('large') {
		width: 200px;
	}
	& input {
		width: 100%;
		border: none;
		// border-bottom: 1px solid $accent;
		padding: side-values(0px 15px 0px 35px);
		height: 50px;
		font-size: 16px;
		font-weight: 700;
		text-indent: 5px;
		background-color: #fff;
		color: $accent;
		transition: all 0.2s ease;
		box-shadow: 0 0 15px rgba($color: #000000, $alpha: 0.1);
		@include respond-to('large') {
			font-size: 14px;
			padding: side-values(0px 5px 0px 25px);

		}
		&::placeholder {
			font-size: 16px;
			font-weight: 700;
			color: $accent;
			@include respond-to('large') {
				font-size: 14px;
			}
		}
	}
	& button {
		position: absolute;
		top: 12px;
		#{$right}: 10px;
		border: none;
		background: none;
		transition: all 0.2s ease;
		@include respond-to('large') {
			#{$right}: 5px;
		}
		& i {
			color: $accent;
			font-size: 19px;
		}
	}
}

.main-navi {
	float: $right;
	& > ul {
		display: inline-block;
		list-style: none;
		margin-#{$right}: 60px;
		margin-top: 65px;
		margin-bottom: 0;
		padding: 0;
		transition: margin 0.2s ease;
		@include respond-to('huge') {
			margin-top: 52px;
			margin-#{$right}: 40px;
		}
		@include respond-to('1100') {
			margin-#{$right}: 17px;
		}
		@include respond-to('1100') {
			margin-top: 38px;
		}
		& > li {
			float: $left;
			margin-#{$left}: 40px;
			position: relative;
			@include respond-to('huge') {
				margin-#{$left}: 20px;
			}
			@include respond-to('1100') {
				margin-#{$left}: 20px;
			}
			&:last-child {
				margin-#{$left}: 80px;
				@include respond-to('huge') {
					margin-#{$left}: 40px;
				}
				@include respond-to('1100') {
					margin-#{$left}: 20px;
				}
				& a {
					@include link_on-hover($accent, $text);
				}
			}
			&:first-child {
				margin-#{$left}: 0;
			}
			& > a {
				font-size: 20px;
				font-weight: 700;
				color: $text;
				display: block;
				transition: color 0.2s ease;
				@include link_on-hover($text, $accent);
				@include respond-to('large') {
					font-size: 18px;
				}
				&.active {}
			}
			&:hover {
				& .drop-menu {
					transform: scaleY(1) translateX(-50%);
					max-height: 100vh;
					opacity: 1;
					z-index: 1;
				}
			}
		}
	}
}

.header-mobile {
	display: none;
	background-color: rgba($color: #e7eef3, $alpha: 0.9);
	box-shadow: 0 0 15px 0 rgba($color: #000000, $alpha: 0.3);
	height: 70px;
	@include respond-to('medium') {
		display: block;
	}
	&__logo {
		margin-#{$right}: 15px;
		margin-top: 5px;
		float: $right;
		& > img {
			width: 150px;
		}
	}
	&__btn {
		float: $left;
	}
	&__tel {
		display: inline-block;
		margin-top: 17px;
		i {
			color: $text;
			font-size: 32px;
		}
	}
	
}

.main-header.scrolling {
	.header-desktop {
		height: 100px;
		background-color: rgba($color: #e7eef3, $alpha: 0.9);
		box-shadow: 0 0 15px 0 rgba($color: #000000, $alpha: 0.3);
		@include respond-to('1100') {
			height: 85px;
		}
	}
	.logo {
		padding-top: 8px;
		@include respond-to('1100') {
			padding-top: 5px;
		}
		& img {
			max-width: 205px;
			@include respond-to('large') {
				max-width: 190px;
			}
			@include respond-to('1100') {
				max-width: 170px;
			}
		}
	}
	.header-search {
		margin-top: 25px;
		@include respond-to('1100') {
			margin-top: 17px;
		}
	}
	.main-navi {
		ul {
			margin-top: 37px;
			@include respond-to('large') {
				margin-top: 39px;
			}
			@include respond-to('1100') {
				margin-top: 32px;
			}
		}
	}
}



.drop-menu {
	position: absolute;
	left: 50%;
	top: 100%;
	transform: scaleY(0) translateX(-50%);
	max-height: 0;
	min-width: 310px;
	opacity: 0;
	margin: 0 auto;
	transform-origin: top;
	transition: all 0.3s ease;
	background-color: rgba($color: $title, $alpha: 1);
	& ul {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 10px 35px;
		list-style: none;
		margin: 0;
		&::before {
			content: '';
			position: absolute;
			z-index: -1;
			top: -2px;
			left: 50%;
			transform: rotate(45deg) translateX(-50%);
			width: 15px;
			height: 15px;
			background-color: $title;
			pointer-events: none;
		}
	}
	& li {
		width: 100%;
		display: block;
		&:last-child {
			& a {
				border-bottom: 1px solid transparent;
			}
		}
		& a {
			display: block;
			border-bottom: 1px solid #fff;
			padding: 14px 0px;
			color: $text;
			text-align: center;
			font-size: 20px;
			font-weight: 600;
			line-height: 0.9;
			transition: background 0.3s ease, border 0.3s ease;
			@include respond-to('large') {
				// font-size: 20px;
				padding: 10px 0px;
			}
			&:hover {
				text-decoration: none;
				font-weight: 600;
				color: $accent !important;
			}
			&:focus,
			&:active {
				text-decoration: none;
			}
			// &.active {
			// 	color: #fff;
			// }
		}
	}
} 