.wrapper-all-content {
	background-color: #fff;
	position: relative;
	width: 100%;
	&_bg {
		background-position: top center;
		// background-repeat: no-repeat;
		&::after {
			content: '';
			position: absolute;
			z-index: 1;
			top: -175px;
			left: 0;
			right: 0;
			height: 175px;
			width: 100%;
			background-image: url('../images/main-bg-top.png');
			background-position: top center;
			// background-repeat: no-repeat;
			margin: 0 auto;
		}
	}
}

.title {
	margin: 0;
	font-size: 40px;
	color: $text;
	font-weight: 700;
	line-height: 1;
	@include respond-to ('medium') {
		font-size: 35px;
	}
	@include respond-to ('small') {
		font-size: 30px;
	}
	@include respond-to ('extra-small') {
		font-size: 25px;
	}
	&_tac {
		text-align: center;
	}
	&_mb {
		margin-bottom: 45px;
		@include respond-to('medium') {
			margin-bottom: 35px;
		}
		@include respond-to('small') {
			margin-bottom: 25px;
		}
	}
	&_white {
		color: #fff;
	}
}

.text {
	font-size: 20px;
	font-weight: 400;
	color: $text;
	line-height: 1.4;
	@include respond-to('medium') {
		font-size: 19px;
	}
	@include respond-to('small') {
		font-size: 18px;
	}
	@include respond-to('extra-small') {
		font-size: 17px;
		text-align: justify;
	}
	&_white {
		color: #fff;
	}
}

.sec-padd {
	padding: 100px 0;
	@include respond-to('huge') {
		padding: 80px 0;
	}
	@include respond-to('1100') {
		padding: 50px 0;
	}
	@include respond-to('medium') {
		padding: 40px 0;
	}
	@include respond-to('small') {
		padding: 30px 0;
	}
	@include respond-to('extra-small') {
		padding: 25px 0;
	}
}

.hp-about {
	padding-bottom: 150px;
	@include respond-to('huge') {
		padding-bottom: 100px;
	}
	@include respond-to('1100') {
		padding-bottom: 50px;
	}
	@include respond-to('medium') {
		padding-bottom: 40px;
	}
	@include respond-to('small') {
		padding-bottom: 30px;
	}
	@include respond-to('extra-small') {
		padding-bottom: 25px;
	}
	&__title {}
	&__text {
		text-align: center;
		@include respond-to('extra-small') {
			text-align: justify;
		}
	}
	&__photo {
		position: relative;
		height: 300px;
		margin-top: 50px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		outline: 2px solid #fff;
		outline-offset: -12px;
		@include respond-to('1100') {
			margin-bottom: 30px;
		}
		@include respond-to('small') {
			height: 250px;
			margin-top: 30px;
		}
	}
	&__button {
		position: absolute;
		bottom: -25px;
		left: 0;
		right: 0;
		@include respond-to('small') {
			bottom: -18px;
		}
	}
}

.main-btn {
	max-width: 200px;
	width: 100%;
	height: 70px;
	margin: 0 auto;
	padding: 20px;
	background: $accent;
	text-align: center;
	font-weight: 600;
	font-size: 20px;
	border: 2px solid #fff;
	@include link_no-hover(#fff);
	&:active {
		@include btn-effect-active();
	}
	@include respond-to('small') {
		max-width: 180px;
		height: 50px;
		padding: 10px;
	}
	&_blue {
		background: $text;
		border: 2px solid $text;
	}
}

.hp-info {
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	@include respond-to('medium') {
		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($color: #000000, $alpha: 0.3);
		}
	}
	@include respond-to('small') {
		&::after {
			background-color: rgba($color: #000000, $alpha: 0.6);
		}
	}
	&__left {
		position: relative;
		z-index: 1;
		padding: 70px 0;
		float: $left;
		max-width: 500px;
		@include respond-to('huge') {
			padding: 50px 0;
		}
		@include respond-to('large') {
			padding: 40px 0;
		}
		@include respond-to('medium') {
			padding: 30px 0;
		}
		@include respond-to('small') {
			padding: 20px 0;
		}
	}
	&__title {
		@include respond-to('extra-small') {
			text-align: center;
		}
	}
	&__text {}
}