.breadcrumb {
	background-color: transparent;
	margin-bottom: 0;
	padding: 16px 0;
	border-bottom: 1px solid #d1d1d1;
	border-radius: 0;
	@include respond-to('small') {
		display: none;
	}
	& li {
		font-size: 18px;
		&::before {
			color: #b1b1b1;
		}
		& a {
			text-decoration: none;
			color: #b1b1b1;
			font-size: 18px;
			font-weight: 400;
		}
	}
	& .active {
		color: $text;
	}
}
